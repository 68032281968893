import { spacing } from '@typeform/ginger/dist/util/spacing'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'
import Grid from 'components/atoms/grid-container/next'
import Text, {
  SIZE as TextSize,
} from '@typeform/ginger/dist/components/typography/text/next'
import LogosModule, {
  Container as LogosModuleContainer,
} from '@typeform/ginger/dist/components/logos-module'
import React from 'react'

import { TRatingsModuleProps } from './types'
import BaseStars from './stars'

export const Container = styled(Grid).attrs(() => ({
  as: 'section',
}))`
  row-gap: 0;
  padding: ${spacing(8, 2)};
`

export const Stars = styled.div.attrs(() => ({
  children: <BaseStars />,
}))`
  grid-column: 1 / -1;
  line-height: 0;
  justify-self: center;
  margin-bottom: ${spacing(3)};
`

export const Title = styled(Text).attrs(() => ({
  size: TextSize.M,
}))`
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: ${spacing(3)};

  ${mediaQueries.sm`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQueries.md`
    margin-bottom: ${spacing(6)};
  `}
`

export const Logos = styled.div.attrs(attrs => ({
  // @ts-expect-error TODO: migrate LogosModule to TS
  children: <LogosModule as='div' type='partners' {...attrs} />,
}))<{ logos: TRatingsModuleProps['logos'] }>`
  grid-column: 1 / -1;

  ${LogosModuleContainer} {
    padding: 0;
    margin: ${spacing(0, -2)};
  }
`
