import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import React, { memo } from 'react'

const Stars: React.FC<{
  color?: string
}> = ({ color = NeutralColors.Ink }) => {
  return (
    <svg
      width='193'
      height='32'
      viewBox='0 0 193 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.1966 2.85485C16.3186 2.60047 16.6827 2.60047 16.8047 2.85485L20.381 10.3103C20.4301 10.4127 20.5279 10.4834 20.641 10.4982L28.8741 11.5778C29.155 11.6147 29.2675 11.959 29.062 12.1531L23.0392 17.8404C22.9565 17.9185 22.9191 18.0329 22.9399 18.1445L24.4519 26.2671C24.5035 26.5443 24.209 26.7571 23.96 26.6227L16.6613 22.6822C16.5611 22.6281 16.4402 22.6281 16.34 22.6822L9.04135 26.6227C8.79232 26.7571 8.49783 26.5443 8.54942 26.2671L10.0614 18.1445C10.0822 18.0329 10.0448 17.9185 9.96211 17.8404L3.93932 12.1531C3.73382 11.959 3.8463 11.6147 4.12722 11.5778L12.3603 10.4982C12.4734 10.4834 12.5712 10.4127 12.6203 10.3103L16.1966 2.85485Z'
        fill={color}
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M56.1966 2.85485C56.3186 2.60047 56.6827 2.60047 56.8047 2.85485L60.381 10.3103C60.4301 10.4127 60.5279 10.4834 60.641 10.4982L68.8741 11.5778C69.155 11.6147 69.2675 11.959 69.062 12.1531L63.0392 17.8404C62.9565 17.9185 62.9191 18.0329 62.9399 18.1445L64.4519 26.2671C64.5035 26.5443 64.209 26.7571 63.96 26.6227L56.6613 22.6822C56.5611 22.6281 56.4402 22.6281 56.34 22.6822L49.0413 26.6227C48.7923 26.7571 48.4978 26.5443 48.5494 26.2671L50.0614 18.1445C50.0822 18.0329 50.0448 17.9185 49.9621 17.8404L43.9393 12.1531C43.7338 11.959 43.8463 11.6147 44.1272 11.5778L52.3603 10.4982C52.4734 10.4834 52.5712 10.4127 52.6203 10.3103L56.1966 2.85485Z'
        fill={color}
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.1966 2.85485C96.3186 2.60047 96.6827 2.60047 96.8047 2.85485L100.381 10.3103C100.43 10.4127 100.528 10.4834 100.641 10.4982L108.874 11.5778C109.155 11.6147 109.267 11.959 109.062 12.1531L103.039 17.8404C102.956 17.9185 102.919 18.0329 102.94 18.1445L104.452 26.2671C104.503 26.5443 104.209 26.7571 103.96 26.6227L96.6613 22.6822C96.5611 22.6281 96.4402 22.6281 96.34 22.6822L89.0413 26.6227C88.7923 26.7571 88.4978 26.5443 88.5494 26.2671L90.0614 18.1445C90.0822 18.0329 90.0448 17.9185 89.9621 17.8404L83.9393 12.1531C83.7338 11.959 83.8463 11.6147 84.1272 11.5778L92.3603 10.4982C92.4734 10.4834 92.5712 10.4127 92.6203 10.3103L96.1966 2.85485Z'
        fill={color}
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.197 2.85485C136.319 2.60047 136.683 2.60047 136.805 2.85485L140.381 10.3103C140.43 10.4127 140.528 10.4834 140.641 10.4982L148.874 11.5778C149.155 11.6147 149.267 11.959 149.062 12.1531L143.039 17.8404C142.956 17.9185 142.919 18.0329 142.94 18.1445L144.452 26.2671C144.503 26.5443 144.209 26.7571 143.96 26.6227L136.661 22.6822C136.561 22.6281 136.44 22.6281 136.34 22.6822L129.041 26.6227C128.792 26.7571 128.498 26.5443 128.549 26.2671L130.061 18.1445C130.082 18.0329 130.045 17.9185 129.962 17.8404L123.939 12.1531C123.734 11.959 123.846 11.6147 124.127 11.5778L132.36 10.4982C132.473 10.4834 132.571 10.4127 132.62 10.3103L136.197 2.85485Z'
        fill={color}
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M168.907 26.3571L176.366 22.1359V2.39844C176.244 2.39839 176.123 2.46199 176.062 2.58923L172.486 10.0447C172.436 10.1471 172.339 10.2178 172.226 10.2326L163.992 11.3122C163.712 11.349 163.599 11.6934 163.805 11.8875L169.827 17.5748C169.91 17.6529 169.947 17.7673 169.927 17.8788L168.415 26.0015C168.363 26.2787 168.658 26.4915 168.907 26.3571Z'
        fill={color}
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.197 2.85485C176.319 2.60047 176.683 2.60047 176.805 2.85485L180.381 10.3103C180.43 10.4127 180.528 10.4834 180.641 10.4982L188.874 11.5778C189.155 11.6147 189.267 11.959 189.062 12.1531L183.039 17.8404C182.956 17.9185 182.919 18.0329 182.94 18.1445L184.452 26.2671C184.503 26.5443 184.209 26.7571 183.96 26.6227L176.661 22.6822C176.561 22.6281 176.44 22.6281 176.34 22.6822L169.041 26.6227C168.792 26.7571 168.498 26.5443 168.549 26.2671L170.061 18.1445C170.082 18.0329 170.045 17.9185 169.962 17.8404L163.939 12.1531C163.734 11.959 163.846 11.6147 164.127 11.5778L172.36 10.4982C172.473 10.4834 172.571 10.4127 172.62 10.3103L176.197 2.85485Z'
        stroke={color}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default memo(Stars)
