import { getStaticAsset } from 'utils/assets'

const ASSETS_PATH = '/homepage/partners'

export type TLogoConfig = {
  name: string
  url: string
}

const AirBnbLogo: TLogoConfig = {
  name: 'Airbnb',
  url: getStaticAsset(`${ASSETS_PATH}/airbnb-logo.svg`),
}
const MailChimpLogo: TLogoConfig = {
  name: 'Mailchimp',
  url: getStaticAsset(`${ASSETS_PATH}/mailchimp-logo.svg`),
}
const HermesLogo: TLogoConfig = {
  name: 'Hermès',
  url: getStaticAsset(`${ASSETS_PATH}/hermes-logo.svg`),
}
const HubspotLogo: TLogoConfig = {
  name: 'HubSpot',
  url: getStaticAsset(`${ASSETS_PATH}/hubspot-logo.svg`),
}
const BarrysLogo: TLogoConfig = {
  name: "Barry's",
  url: getStaticAsset(`${ASSETS_PATH}/barrys-logo.svg`),
}

export const PARTNER_LOGOS: TLogoConfig[] = [
  AirBnbLogo,
  MailChimpLogo,
  HermesLogo,
  HubspotLogo,
  BarrysLogo,
]
