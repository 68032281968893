export enum Variant {
  Stars = 'Stars',
  Partners = 'Partners',
}

// TODO: update type once LogosModule gets migrated to TS
export type TLogo = {
  name: string
  url: string
}

type TLogos = TLogo[]

type TBase = React.HTMLAttributes<HTMLDivElement> & {
  variant?: Variant
  title?: string
  logos?: TLogos
  hideTitle?: boolean
}

export type TLogosVariant = TBase & {
  variant: Variant.Partners
  logos: TLogos
}

export type TRatingsModuleProps = TBase | TLogosVariant
