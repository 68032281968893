import React, { memo } from 'react'
import RatingsModule, {
  TRatingsModuleProps,
  Variant,
} from 'components/modules/ratings-module'

import { PARTNER_LOGOS } from './constants'

const PartnersSection = (
  props: Omit<TRatingsModuleProps, 'variant' | 'logos'>
) => (
  <RatingsModule
    hideTitle
    variant={Variant.Partners}
    logos={PARTNER_LOGOS}
    {...props}
  />
)

PartnersSection.displayName = 'PartnersSection'

export default memo(PartnersSection)
